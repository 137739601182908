import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Layout from '../../../../layouts'
import Helmet from 'react-helmet'
import { Link } from 'gatsby'
import { stiplyScreenshot, employesUnderline, stiply } from 'images/koppelingen'

const dummyContent = {
  content: [{
    title: 'Employes & Stiply ',
    description: 'Dankzij de integratie met Stiply geef je jouw medewerkers de mogelijkheid om contracten te ondertekenen én kun jij als werkgever contracten voorzien van een digitale handtekening. Het ondertekenen van contracten hoeft geen langdurig en ingewikkeld proces te zijn. Je hebt direct inzicht in de status van een document. Na ondertekening kun je het afgeronde document vanuit Employes inzien en downloaden. Stiply staat voor efficiëntie en het maakt je werk een stuk makkelijker.'
    }, {
      image: stiplyScreenshot
    },{
      title: 'Wat kost het?',
      description: 'De  kosten voor deze koppeling zijn slechts € 1,- per ondertekenverzoek (excl. btw). Ook als je enkele werknemers in dienst hebt, kun je gebruik maken van de oplossing. Je kunt de koppeling direct activeren vanuit Employes, je hoeft dus geen account aan te maken bij Stiply. Wel zo handig! Meer weten? Neem contact op via support@employes.nl.'
    }
  ]
}

class Stiply extends Component {
  render() {
    return (
      <Layout location={this.props.location}>
        <main className="koppeling single">

          <Helmet>
            <title>Koppeling Stiply & Employes | HR</title>
            <meta name="description" content="Digitaal ondertekenen van documenten met de integratie tussen Employes en Stiply."/>
            <meta itemprop="name" content="Koppeling Stiply & Employes | HR" />
            <meta itemprop="description" content="Digitaal ondertekenen van documenten met de integratie tussen Employes en Stiply."/>
            <meta itemprop="image" content="/static/meta-img.png" />

            {/* OpenGraph tags */}
            <meta property="og:url" content="https://employes.nl/product/koppelingen/stiply/" />
            <meta property="og:type" content="website" />
            <meta property="og:title" content="Koppeling Stiply & Employes | HR" />
            <meta property="og:description" content="Digitaal ondertekenen van documenten met de integratie tussen Employes en Stiply." />
            <meta property="og:image" content="https://employes.nl/static/meta-img.png" />

            {/* Twitter Card tags */}
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:title" content="Koppeling Stiply & Employes | HR" />
            <meta name="twitter:description" content="Digitaal ondertekenen van documenten met de integratie tussen Employes en Stiply." />
            <meta name="twitter:image" content="https://employes.nl/static/meta-img.png" />

          </Helmet>


          <header className="koppeling stiply padding-xxl">
            <div className="flakes flakes--left"></div>
            <div className="flakes flakes--right"></div>

            <div className="container-sm">
              <div className="grid yg align-middle">
                <div className="col-6">
                  <h1>Digitaal ondertekenen met Stiply en Employes.</h1>
                </div>

                <div className="col-6">
                  <div className="koppeling-image">
                    <img className="margin-s-bottom" src={employesUnderline}/>

                    <svg className="margin-s-bottom" xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 64 64">
                      <path fill="#DAE6F0" fillRule="evenodd" d="M167,130 L198,130 L198,131 L167,131 L167,163 L165,163 L165,131 L134,131 L134,130 L165,130 L165,99 L167,99 L167,130 Z" opacity=".4" transform="translate(-134 -99)"/>
                    </svg>

                    <img src={stiply}/>
                  </div>
                </div>
              </div>
            </div>
          </header>

          <section className="section-content padding-xl-top">
            <div>
                {dummyContent.content.map((section, idx) => {
                  const firstObjectKey = Object.keys(section)[0]
                  const isImage = (firstObjectKey === 'image')
                  const { title, description } = section

                  return (
                    <div className="section margin-m-bottom" key={idx}>
                      <div className={`container-${ isImage ? 'md image' : 'sm' }`} key={idx}>
                        <div className="grid yg center">
                          <div className={`col-12 ${isImage ? 'no-pad image-container' : 'padding-m'}`}>
                          {{
                            title: (
                              <div className="text">
                                <h4>{ title }</h4>
                                <p>{ description}</p>
                              </div>
                            ),
                            image: (
                              <img src={section.image}/>
                            )
                          }[firstObjectKey]}
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                })
              }
            </div>
          </section>

          <div className="section margin-m-bottom">
            <div className="container-sm">
              <div className="grid yg center">
                <div className="col-12">
                  <Link to="/product/koppelingen/" className="link blue">Terug naar koppelingen</Link>
                </div>
              </div>
            </div>
          </div>

        </main>
      </Layout>
    )
  }
}

Stiply.propTypes = {
  data: PropTypes.object
}

export default Stiply
